
<template>
  <div id="item-detail-page">
    <!--    <vs-alert color="danger" title="Error Fetching Product Data" :active.sync="error_occured">-->
    <!--      <span>{{ error_msg }}. </span>-->
    <!--      <span>-->
    <!--        <span>Check </span><router-link :to="{name:'ecommerce-shop'}" class="text-inherit underline">All Items</router-link>-->
    <!--      </span>-->
    <!--    </vs-alert>-->

    <vx-card>
      <!--    <vx-card v-if="item_data" :key="item_data.objectID">-->

      <template slot="no-body" v-if="!!item_data">
        <div class="item-content">
          <!-- Item Main Info -->
          <div class="product-details p-6">
            <div class="vx-row mt-6">
              <div
                class="vx-col md:w-2/5 w-full flex items-center justify-center"
              >
                <div class="product-img-container w-3/5 mx-auto mb-10 md:mb-0">
                  <img
                    :src="item_data.ImageUrl"
                    @error="replaceByDefault"
                    style="max-width: 400px"
                    :alt="item_data.Id"
                  />

                  <div class="vx-row mt-16">
                    <div class="vx-col md:w-full w-full md:mb-0">
                      <span
                        >Barcode :
                        {{ item_data.Barcode ? item_data.Barcode : '' }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-row mt-4">
                    <div class="vx-col md:w-full w-full md:mb-0">
                      <span>Product ID : {{ item_data.Id }}</span>
                    </div>
                  </div>
                  <div class="vx-row mt-4">
                    <div class="vx-col md:w-full w-full md:mb-0">
                      <span>Product Code : {{ item_data.Code }}</span>
                    </div>
                  </div>
                  <!--                  <div class="vx-row mt-4">-->
                  <!--                    <div class="vx-col  md:w-full w-full md:mb-0">-->
                  <!--                      <span>PRODUCT CODE : {{item_data.Code}}</span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>

              <!-- Item Content -->
              <div class="vx-col md:w-3/5 w-full">
                <div class="d-flex mb-4">
                  <vs-button
                    @click="changeLan('EST')"
                    class="mr-4"
                    v-bind:class="{ 'button-highlighted': lan === 'EST' }"
                    color="success"
                    type="border"
                    icon-pack="feather"
                    >EST</vs-button
                  >
                  <vs-button
                    :key="lan_arr"
                    v-for="lan_arr in current_user_language_array"
                    @click="changeLan(lan_arr)"
                    v-bind:class="{ 'button-highlighted': lan === lan_arr }"
                    class="mr-4"
                    color="success"
                    type="border"
                    icon-pack="feather"
                    >{{ lan_arr }}</vs-button
                  >
                  <vs-button
                    @click="onClickProductUpdate(item_data.Id)"
                    class="float-right"
                    color="warning"
                    type="border"
                    icon-pack="feather"
                    icon="icon-edit"
                    >Edit</vs-button
                  >
                </div>
                <vs-divider />
                <div class="d-inline-flex">
                  <div class="">
                    <span class="title">{{
                      Dtranslations(item_data.Name)
                    }}</span>
                    <vs-button
                      @click="onClickTranslationEditPopUp()"
                      color="success"
                      class="ml-4 btn-add"
                      size="small"
                      type="border"
                      >Translation</vs-button
                    >
                    <!-- <vs-button v-if="item_data.Name" @click="onClickNameTranslation(item_data.Name)" color="warning" class="ml-4 btn-add" size="small" type="border">Add Translation</vs-button> -->
                  </div>
                </div>
                <p class="my-2">
                  <span class="mr-2"
                    ><span style="font-weight: bold">{{
                      item_data.Brand
                        ? Dtranslations(item_data.Brand.Name)
                        : '-'
                    }}</span>
                    by</span
                  >
                  <span
                    ><span style="font-weight: bold">{{
                      item_data.Manufacturer
                        ? item_data.Manufacturer
                        : 'NO MANUFACTURER'
                    }}</span></span
                  >
                </p>
                <p class="flex items-center flex-wrap">
                  <span
                    class="text-2xl leading-none font-medium text-primary mr-4 mt-2"
                  >
                    <span class="mr-0">
                      {{ Dtranslations(item_data.Type.Name) }}
                    </span>
                  </span>
                  <span
                    v-if="item_data.ProductTree"
                    class="cursor-pointer leading-none mt-2"
                  >
                    <span
                      class="mr-1"
                      v-for="group in item_data.ProductTree"
                      :key="group.Name"
                    >
                      {{ Dtranslations(group.Name) }}
                    </span>
                  </span>
                </p>

                <vs-divider />
                <p class="">
                  <!-- {{Dtranslations(item_data.Description.Name)}} -->
                  {{
                    item_data.Description
                      ? Dtranslations(item_data.Description.Name)
                      : '-'
                  }}
                  <!-- <vs-button v-if="item_data.Description" @click="onClickDescriptionTranslation(item_data.Description.Id, item_data.Description.Name)" color="warning" class="ml-4 btn-add" size="small" type="border">Add Translation</vs-button> -->
                  <vs-button
                    v-if="item_data.Description"
                    @click="
                      onClickTranslationEditPopUp(
                        item_data.Description.Id,
                        item_data.Description.Name,
                        'Description',
                      )
                    "
                    color="success"
                    class="ml-4 btn-add"
                    size="small"
                    type="border"
                    >Translation</vs-button
                  >
                </p>
                <vs-list class="product-sales-meta-list px-0 pt-4">
                  <span
                    class="flex"
                    v-if="item_data.Tag"
                    v-for="group in item_data.Tag"
                    :key="group.Id"
                  >
                    <vs-list-item
                      class="p-0 border-none"
                      :title="Dtranslations(group.Name)"
                      icon-pack="feather"
                      icon="icon-tag"
                    />
                    <vs-button
                      v-if="group"
                      @click="onClickTagTranslation(group.Id, group.Name)"
                      color="warning"
                      class="ml-2 btn-add-trans"
                      size="small"
                      type="border"
                      >Add Translation</vs-button
                    >
                  </span>
                  <!--                  <vs-list-item class="p-0 border-none" :title="item_data.Code" icon-pack="feather" icon="icon-box"/>-->
                </vs-list>

                <vs-divider />

                <!-- Quantity -->
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <span>Active Substances</span> <br />
                    <p v-if="!item_data.ActiveSubstance" class="my-2">
                      <span class="text-primary">-</span>
                    </p>
                    <p v-if="item_data.ActiveSubstance" class="my-2">
                      <span class="text-primary mr-4">
                        {{ Dtranslations(item_data.ActiveSubstance.Name) }}
                      </span>
                    </p>
                  </div>
                  <div class="vx-col w-1/2">
                    <p class="my-2">
                      <span>Package Amount</span> <br />
                      <span class="text-primary">{{
                        item_data.PackageAmount ? item_data.PackageAmount : '-'
                      }}</span>
                    </p>
                  </div>
                </div>

                <vs-divider />

                <!-- Quantity -->
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <p class="my-2">
                      <span>Age Restrictions</span> <br />
                      <span class="text-primary">{{
                        item_data.AgeRestriction
                          ? item_data.AgeRestriction
                          : '-'
                      }}</span>
                    </p>
                  </div>
                  <div class="vx-col w-1/2">
                    <p class="my-2">
                      <span>E-Pharmacy Status</span> <br />
                      <span class="text-primary">{{
                        epharmacyStatus(item_data.EPharmacyStatus)
                      }}</span>
                    </p>
                  </div>
                </div>

                <vs-divider />

                <!-- Quantity -->
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p class="my-2">
                      <span>Reason for Use</span> <br />
                      <span class="text-primary">{{
                        item_data.ReasonForUse
                          ? Dtranslations(item_data.ReasonForUse.Name)
                          : '-'
                      }}</span>
                      <!-- <vs-button v-if="item_data.ReasonForUse" @click="onClickReasonForUseTranslation(item_data.ReasonForUse.Id, item_data.ReasonForUse.Name)" color="warning" class="ml-4 btn-add" size="small" type="border">Add Translation</vs-button> -->
                      <vs-button
                        v-if="item_data.ReasonForUse"
                        @click="
                          onClickTranslationEditPopUp(
                            item_data.ReasonForUse.Id,
                            item_data.ReasonForUse.Name,
                            'ReasonForUse',
                          )
                        "
                        color="success"
                        class="ml-4 btn-add"
                        size="small"
                        type="border"
                        >Translation</vs-button
                      >
                    </p>
                  </div>
                </div>

                <vs-divider />
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p class="my-2">
                      <span>Usage Instructions</span> <br />
                      <span class="text-primary mr-4">
                        {{
                          item_data.UsageInstruction
                            ? Dtranslations(item_data.UsageInstruction.Name)
                            : '-'
                        }}
                        <!-- <vs-button v-if="item_data.UsageInstruction" @click="onClickUsageInstructionsTranslation(item_data.UsageInstruction.Id, item_data.UsageInstruction.Name)" color="warning" class="ml-4 btn-add" size="small" type="border">Add Translation</vs-button> -->
                        <vs-button
                          v-if="item_data.UsageInstruction"
                          @click="
                            onClickTranslationEditPopUp(
                              item_data.UsageInstruction.Id,
                              item_data.UsageInstruction.Name,
                              'UsageInstruction',
                            )
                          "
                          color="success"
                          class="ml-4 btn-add"
                          size="small"
                          type="border"
                          >Translation</vs-button
                        >
                      </span>
                    </p>
                  </div>
                </div>

                <vs-divider />

                <!-- Quantity -->
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <p class="my-2">
                      <span>Storage Temperature</span> <br />
                      <span class="text-primary">{{
                        item_data.StorageTemperature
                          ? Dtranslations(item_data.StorageTemperature.Name)
                          : '-'
                      }}</span>
                      <!--                      <p>{{item_data.SPC ? item_data.SPC : 'NULL'}}</p>-->
                    </p>
                  </div>

                  <div class="vx-col w-1/2">
                    <p class="my-2">
                      <span>Storage Requirement</span> <br />
                      <span class="text-primary">{{
                        item_data.StorageRequirement
                          ? Dtranslations(item_data.StorageRequirement.Name)
                          : '-'
                      }}</span>
                      <!--                      <p>{{item_data.SPC ? item_data.SPC : 'NULL'}}</p>-->
                    </p>
                  </div>
                </div>

                <vs-divider />

                <!-- Quantity -->
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p class="my-2">
                      <span>PIL URL</span> <br />
                      <a
                        class="text-primary"
                        target="_blank"
                        :href="Dtranslations(item_data.PIL)"
                      >
                        {{ item_data.PIL ? Dtranslations(item_data.PIL) : '-' }}
                      </a>
                    </p>
                  </div>
                  <div class="vx-col w-full">
                    <p class="my-2">
                      <span>SPC URL</span> <br />
                      <a
                        class="text-primary"
                        target="_blank"
                        :href="Dtranslations(item_data.SPC)"
                      >
                        {{ item_data.SPC ? Dtranslations(item_data.SPC) : '-' }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vx-card>
    <product-description-pop-up
      v-on:dataReload="dataReload"
      ref="productDescriptionTranslationPopUp"
    />
    <product-reason-for-use-pop-up
      v-on:dataReload="dataReload"
      ref="productReasonForUseTranslationPopUp"
    />
    <product-usage-instructions-pop-up
      v-on:dataReload="dataReload"
      ref="productUsageInstructionsTranslationPopUp"
    />
    <product-name-pop-up
      v-on:dataReload="dataReload"
      ref="productNameTranslationPopUp"
    />
    <product-tag-pop-up
      v-on:dataReload="dataReload"
      ref="productTagTranslationPopUp"
    />
    <!-- <product-translations-edit-pop-up ref="productTranslationsEditPopup"/> -->
    <product-update-pop-up ref="productUpdatePopUP" />
    <product-translations-edit-popup
      v-on:dataReload="dataReload"
      ref="productTranslationsEditPopup"
    />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import algoliasearch from 'algoliasearch/lite';
import StarRating from 'vue-star-rating';
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ProductRepository = RepositoryFactory.get('product');
import ProductDescriptionPopUp from '@/components/popups/translations/ProductDescriptionPopUp';
import ProductReasonForUsePopUp from '@/components/popups/translations/ProductReasonForUsePopUp';
import ProductUsageInstructionsPopUp from '@/components/popups/translations/ProductUsageInstructionsPopUp';
import ProductNamePopUp from '@/components/popups/translations/ProductNamePopUp';
import ProductTagPopUp from '@/components/popups/translations/ProductTagPopUp';
import ProductTranslationsEditPopup from '@/components/popups/translations/ProductTranslationsEditPopup';
import ProductUpdatePopUp from '@/components/popups/products/ProductUpdatePopUp';
import store from '../../../store/store';
//import ProductTranslationsEditPopup from '../../../components/popups/translations/ProductTranslationsEditPopup.vue'

export default {
  components: {
    StarRating,
    ProductDescriptionPopUp,
    ProductReasonForUsePopUp,
    ProductUsageInstructionsPopUp,
    ProductNamePopUp,
    ProductTagPopUp,
    ProductUpdatePopUp,
    ProductTranslationsEditPopup,
  },
  data() {
    return {
      productId: this.$route.params.id,
      algolia_index: algoliasearch(
        'latency',
        '6be0576ff61c053d5f9a3225e2a90f76',
      ).initIndex('instant_search'),
      item_data: null,
      error_occured: false,
      error_msg: '',
      placeholder_image: 'https://i.stack.imgur.com/y9DpT.jpg',
      lan: 'EST',
      current_user_language_array: store.getters.current_user_language_array,

      // Below is data which is common in any item
      // Algolia's dataSet don't provide this kind of data. So, here's dummy data for demo
      available_item_colors: [
        '#7367F0',
        '#28C76F',
        '#EA5455',
        '#FF9F43',
        '#1E1E1E',
      ],
      opt_color: '#7367F0',
      is_hearted: false,

      related_items: [
        {
          name:
            'Apple - Apple Watch Series 1 42mm Space Gray Aluminum Case Black Sport Band - Space Gray Aluminum',
          brand: 'Apple',
          price: 229,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/01.png',
          rating: 4,
          objectID: '5546604',
        },
        {
          name:
            'Beats by Dr. Dre - Powerbeats2 Wireless Earbud Headphones - Black/Red',
          brand: 'Beats by Dr. Dre',
          price: 199.99,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/08.png',
          rating: 4,
          objectID: '5565002',
        },
        {
          name: 'Amazon - Fire TV Stick with Alexa Voice Remote - Black',
          brand: 'Amazon',
          price: 39.99,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/03.png',
          rating: 4,
          objectID: '5477500',
        },
        {
          name:
            'Apple - Apple Watch Nike+ 42mm Silver Aluminum Case Silver/Volt Nike Sport Band - Silver Aluminum',
          brand: 'Apple',
          price: 399,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/07.png',
          rating: 4,
          objectID: '5547700',
        },
        {
          name: 'Google - Chromecast Ultra - Black',
          brand: 'Google',
          price: 69.99,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/05.png',
          rating: 4,
          objectID: '5578628',
        },
        {
          name: 'Beats by Dr. Dre - Beats EP Headphones - White',
          brand: 'Beats by Dr. Dre',
          price: 129.99,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/02.png',
          rating: 4,
          objectID: '5577781',
        },
        {
          name: 'LG - 40" Class (39.5" Diag.) - LED - 1080p - HDTV - Black',
          brand: 'LG',
          price: 279.99,
          image:
            'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/09.png',
          rating: 4,
          objectID: '5613404',
        },
      ],
      //  stockLevel: null
    };
  },
  computed: {
    itemColor() {
      return (obj) => {
        const style_obj = {};

        obj.style.forEach((p) => {
          style_obj[p] = obj.color;
        });

        return style_obj;
      };
    },
  },
  methods: {
    async dataReload() {
      //console.log(lan)
      this.$vs.loading();
      this.item_data = (
        await ProductRepository.details(this.productId)
      ).data[0];
      console.log('awa');
      this.$vs.loading.close();
    },
    async dataRefresh() {
      await this.dataReload();
    },
    async changeLan(lan) {
      this.lan = lan;
      //await this.dataRefreshWithLanguage()
    },
    async dataRefreshWithLanguage() {
      await this.$vs.loading();
      await this.dataReload();
      this.$vs.loading.close();
    },
    fetch_item_details(id) {
      this.algolia_index.getObject(id, (err, content) => {
        if (err) {
          this.error_occured = true;
          this.error_msg = err.message;
          console.error(err);
        } else {
          this.item_data = content;
        }
      });
    },
    updateNames(updatedNames) {
      this.names = updatedNames;
      console.log(updatedNames, 'jjjddjdjj');
    },
    async onClickDescriptionTranslation(id, names) {
      console.log(names, id);
      await this.$refs.productDescriptionTranslationPopUp.openPopup(
        this.item_data.Id,
        id,
        names,
      );
    },
    async onClickReasonForUseTranslation(id, names) {
      await this.$refs.productReasonForUseTranslationPopUp.openPopup(
        this.item_data.Id,
        id,
        names,
      );
    },
    async onClickUsageInstructionsTranslation(id, names) {
      await this.$refs.productUsageInstructionsTranslationPopUp.openPopup(
        this.item_data.Id,
        id,
        names,
      );
    },
    async onClickNameTranslation(names) {
      await this.$refs.productNameTranslationPopUp.openPopup(
        this.item_data.Id,
        names,
      );
    },
    async onClickTagTranslation(id, names) {
      await this.$refs.productTagTranslationPopUp.openPopup(
        this.item_data.Id,
        id,
        names,
      );
    },

    async onClickTranslationEditPopUp() {
      await this.$refs.productTranslationsEditPopup.openPopup(
        this.item_data.Id,
      );
    },

    async onClickTranslationEditPopUp(id, names, type) {
      await this.$refs.productTranslationsEditPopup.openPopupAttribute(
        this.item_data.Id,
        names,
        id,
        type,
      );
    },
    // async onClickTranslationEditPopUp(id, names){
    //   await this.$refs.productTranslationsEditPopup.openPopupReason(this.item_data.Id, id, names)
    // },
    // async onClickTranslationEditPopUp(id, names){
    //   await this.$refs.productTranslationsEditPopup.openPopupUsage(this.item_data.Id, id, names)
    // },
    async onClickProductUpdate(id) {
      await this.$refs.productUpdatePopUP.openPopup(id);
    },
    epharmacyStatus(term) {
      switch (term) {
        case true:
          return 'YES';
        case false:
          return 'NO';
        default:
          return 'N/A';
      }
    },
    replaceByDefault(e) {
      e.target.src = this.placeholder_image;
    },
    Dtranslations(Name) {
      if (!Name) {
        return '';
      }
      const name = Name.find((n) => {
        return n.Language === this.lan;
      });
      if (!!name) {
        return name.Value;
      } else {
        return Name.find((n) => {
          return n.Language === 'EST';
        }).Value;
      }
    },
  },

  async created() {
    // this.fetch_item_details(this.$route.params.item_id)
    this.$vs.loading();
    this.item_data = (await ProductRepository.details(this.productId)).data[0];
    //this.stockLevel = ((await ProductRepository.stockLevel(this.productId)))
    // console.log(this.stockLevel)
    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';

.__product-name-button {
  display: none !important;
}

.__product-name:hover .__product-name-button {
  display: block !important;
}

#item-detail-page {
  .color-radio {
    height: 2.28rem;
    width: 2.28rem;

    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    background-color: #f7f7f7;

    .theme-dark & {
      background-color: $theme-dark-secondary-bg;
    }
  }

  .product-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-product-swiper {
    // padding-right: 2rem;
    // padding-left: 2rem;

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1),
          0 5px 12px 0 rgba(0, 0, 0, 0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(0.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}
.btn-add-trans {
  display: none;
}
.button-highlighted {
  background: #64c272 !important;
  color: white !important;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
</style>

