<template>
  <vs-popup
    class=""
    id="popup"
    title="Edit Translation"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <vs-button
      @click="onOpenAddTranslationPopUp"
      button="submit"
      class="ml-auto mb-2"
      position="float-right"
      >Add Translations</vs-button
    >
    <vs-table
      :max-items="10"
      :data="name"
      :sst="true"
      stripe
      class="table-dark-inverted table-auto vs-con-loading__container translation-table"
    >
      <template slot="thead">
        <!--            <vs-th sort-key="name">TRANSLATION ID</vs-th>-->
        <vs-th sort-key="name">Language</vs-th>
        <vs-th sort-key="email">Value</vs-th>
        <vs-th>Action</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in name">
          <!--<vs-td :data="data[indextr].TranslationId">
                {{ data[indextr].TranslationId }}
              </vs-td>-->

          <vs-td :name="name[indextr].Language">
            {{ name[indextr].Language }}
          </vs-td>
          <vs-td :name="name[indextr].Value">
            {{ name[indextr].Value }}
          </vs-td>
          <vs-td>
            <div class="vx-row row-btn" v-if="name[indextr].TranslationId">
              <vx-tooltip text="Edit" position="left">
                <!--                    <vs-button @click="popupActive=true"-->
                <vs-button
                  @click="onClickTranslationUpdate(data[indextr].TranslationId)"
                  radius
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip class="ml-3" text="Delete" position="left">
                <!--                    <vs-button @click="popupActive=true"-->
                <vs-button
                  @click="openConfirmDelete(name[indextr].TranslationId)"
                  radius
                  color="danger"
                  type="border"
                  icon-pack="feather"
                  icon="icon-trash-2"
                ></vs-button>
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- Translation Edit Popup -->
    <vs-popup title="TRANSLATION UPDATE" :active.sync="popupActiveUpdate">
      <div class="vx-row mb-3">
        <div class="vx-col md:w-full w-full md:mb-0">
          <small class="label">Language</small>
          <vs-input
            disabled
            v-model="updateSelectedForm.Language"
            class="w-full"
            name="name"
            type="text"
            label=""
          />
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col md:w-full w-full md:mb-0">
          <small class="label">Value</small>
          <vs-input
            v-model="updateSelectedForm.Value"
            class="w-full"
            name="name"
            type="text"
            label=""
          />
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-end mt-8">
        <vs-button @click="onClickUpdate" button="submit" class="ml-auto mt-2"
          >Save Changes</vs-button
        >
        <!--        <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
      </div>
    </vs-popup>
    <!-- Translations Add Popup -->
    <vs-popup
      class=""
      id="popup"
      title="Product Name Translation"
      @close="open = false"
      :active.sync="addpopup"
      :buttons-hidden="true"
    >
      <vs-alert
        color="danger"
        class="h-12 text-center"
        v-if="availableLanArray.length === 0"
      >
        Translation Limit is exceed
      </vs-alert>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form
          v-if="availableLanArray.length > 0"
          @submit.prevent="handleSubmit(onCLickSave)"
          autocomplete="off"
        >
          <div class="vx-row mb-3">
            <div class="vx-col md:w-full w-full md:mb-0">
              <small class="label">Language</small>
              <!--              <vs-input v-model="form.Language" class="w-full" name="name" type="text" label=""/>-->
              <v-select
                v-model="form.Language"
                :options="availableLanArray"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col md:w-full w-full md:mb-0">
              <small class="label">Value</small>
              <vs-textarea
                class="w-full"
                rows="6"
                name="name"
                v-model="form.Value"
              />
              <!-- <small class="label">Value</small>
              <vs-input
                v-model="form.Value"
                class="w-full"
                name="name"
                type="text"
                label=""
              /> -->
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-end mt-8">
            <vs-button button="submit" class="ml-auto mt-2"
              >Save Changes</vs-button
            >
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const TranslationRepository = RepositoryFactory.get('translation');
const ProductRepository = RepositoryFactory.get('product');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import store from '../../../store/store';
import vSelect from 'vue-select';
import ProductDescriptionPopUp from '@/components/popups/translations/ProductDescriptionPopUp';
import ProductReasonForUsePopUp from '@/components/popups/translations/ProductReasonForUsePopUp';
import ProductUsageInstructionsPopUp from '@/components/popups/translations/ProductUsageInstructionsPopUp';
import ProductNamePopUp from '@/components/popups/translations/ProductNamePopUp';

function initialState() {
  return {
    open: false,
    name: {},
    productId: null,
    descriptionId: null,
    current_user_language_array: store.getters.current_user_language_array,
    updateSelectedForm: {},
    availableLanArray: [],
    form: {},
    popupActiveUpdate: false,
    addpopup: false,
    reasonForUseId: null,
    usageId: null,
    type: '',
  };
}

export default {
  name: 'ProductTranslationsEditPopp',
  data() {
    return initialState();
  },
  components: {
    'v-select': vSelect,
    ProductDescriptionPopUp,
    ProductReasonForUsePopUp,
    ProductUsageInstructionsPopUp,
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(productId) {
      this.$vs.loading();
      await Object.assign(this.$data, initialState());
      this.productId = productId;
      this.name = (
        await ProductRepository.details(this.productId)
      ).data[0].Name;
      this.open = true;
      this.$vs.loading.close();
      console.log('djdjdk');
    },
    async openPopupAttribute(productId, names, dId, type) {
      this.$vs.loading();
      await Object.assign(this.$data, initialState());
      this.productId = productId;
      this.descriptionId = dId;
      this.type = type;
      switch (type) {
        case 'Description':
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].Description.Name;
          break;
        case 'ReasonForUse':
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].ReasonForUse.Name;
          break;
        case 'UsageInstruction':
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].UsageInstruction.Name;
          break;
        default:
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].Name;
      }

      this.open = true;
      this.$vs.loading.close();
    },
    openConfirmDelete(id) {
      this.open = false;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure want to delete selected translation from group?',
        accept: this.onClickTranslationDelete,
        parameters: [id],
        cancel: this.reloadPopup,
      });
    },
    async reloadPopup() {
      const vue = this;
      console.log(this.type);
      switch (this.type) {
        case 'Description':
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].Description.Name;
          break;
        case 'ReasonForUse':
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].ReasonForUse.Name;
          break;
        case 'UsageInstruction':
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].UsageInstruction.Name;
          break;
        default:
          this.name = (
            await ProductRepository.details(this.productId)
          ).data[0].Name;
      }

      await vue.$emit('dataReload');

      this.$vs.loading();
      this.open = true;
      this.$vs.loading.close();
    },

    async onClickTranslationDelete(id) {
      this.$vs.loading();
      try {
        await TranslationRepository.delete(id);
        this.$vs.loading.close();
        this.showSuccessMessage('Translation delete successfully!');
        // await vue.$parent.refreshTablelOad()
        //await this.loadGroup()
      } catch (e) {
        this.$vs.loading.close();
        // await vue.$parent.refreshTablelOad()
        this.showErrorMessage('Something went wrong!');
      }
      this.reloadPopup();
      this.$vs.loading.close();
    },
    async onClickTranslationUpdate(id) {
      this.$vs.loading();
      const updateSelectedTranslation = (await TranslationRepository.get(id))
        .data;
      this.updateSelectedForm = updateSelectedTranslation[0];
      this.$vs.loading.close();
      this.popupActiveUpdate = true;
    },
    async onClickUpdate() {
      this.$vs.loading();
      try {
        await TranslationRepository.update(
          this.updateSelectedForm.TranslationId,
          this.updateSelectedForm,
        );
        this.$vs.loading.close();
        this.showSuccessMessage('Translation update successfully!');
        // await vue.$parent.refreshTablelOad()
        await this.reloadPopup();
        this.popupActiveUpdate = false;
      } catch (e) {
        this.$vs.loading.close();
        // await vue.$parent.refreshTablelOad()
        this.showErrorMessage('Something went wrong!');
        this.popupActiveUpdate = false;
      }
      this.$vs.loading.close();
    },
    async onOpenAddTranslationPopUp() {
      this.initialLanguageMap(this.name);
      this.addpopup = true;
    },
    async initialLanguageMap(names) {
      const exists_languages = names.map((m) => m.Language);
      this.availableLanArray = this.current_user_language_array.filter(
        function (obj) {
          return exists_languages.indexOf(obj) === -1;
        },
      );
    },
    async onCLickSave() {
      //const vue = this
      this.$vs.loading();
      try {
        switch (this.type) {
          case 'Description':
            this.name = (
              await ProductRepository.details(this.productId)
            ).data[0].Description.Name;
            await TranslationRepository.productDescriptionAddTranslation(
              this.productId,
              this.descriptionId,
              this.form,
            );
            break;
          case 'ReasonForUse':
            this.name = (
              await ProductRepository.details(this.productId)
            ).data[0].ReasonForUse.Name;
            await TranslationRepository.productReasonForUseAddTranslation(
              this.productId,
              this.descriptionId,
              this.form,
            );
            break;
          case 'UsageInstruction':
            this.name = (
              await ProductRepository.details(this.productId)
            ).data[0].UsageInstruction.Name;
            await TranslationRepository.productUsageInstructionsAddTranslation(
              this.productId,
              this.descriptionId,
              this.form,
            );
            break;
          default:
            await TranslationRepository.productNameAddTranslation(
              this.productId,
              this.form,
            );
        }
        this.$vs.loading.close();
        this.reloadPopup();
        this.addpopup = false;
        this.showSuccessMessage('Product Name Translation Added successfully!');
      } catch (e) {
        this.showErrorMessage('Something went wrong!');
        this.$vs.loading.close();
      }
      this.$vs.loading.close();
    },
  },
};
</script>
<style>
.row-btn {
  width: 100px;
}
</style>


