import { render, staticRenderFns } from "./ProductTagPopUp.vue?vue&type=template&id=be88f38c&scoped=true&"
import script from "./ProductTagPopUp.vue?vue&type=script&lang=js&"
export * from "./ProductTagPopUp.vue?vue&type=script&lang=js&"
import style0 from "./ProductTagPopUp.vue?vue&type=style&index=0&id=be88f38c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be88f38c",
  null
  
)

export default component.exports