<template>
  <vs-popup
    class=""
    id="popup"
    title="REASON FOR USE TRANSLATION"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <vs-alert
      color="danger"
      class="h-12 text-center"
      v-if="availableLanArray.length === 0"
    >
      Translation Limit is exceed
    </vs-alert>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form
        v-if="availableLanArray.length > 0"
        @submit.prevent="handleSubmit(onCLickSave)"
        autocomplete="off"
      >
        <div class="vx-row mb-3">
          <div class="vx-col md:w-full w-full md:mb-0">
            <small class="label">Language</small>
            <!--              <vs-input v-model="form.Language" class="w-full" name="name" type="text" label=""/>-->
            <v-select
              v-model="form.Language"
              :options="availableLanArray"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col md:w-full w-full md:mb-0">
            <small class="label">Value</small>
            <vs-input
              v-model="form.Value"
              class="w-full"
              name="name"
              type="text"
              label=""
            />
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-end mt-8">
          <vs-button button="submit" class="ml-auto mt-2"
            >Save Changes</vs-button
          >
        </div>
      </form>
    </ValidationObserver>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const TranslationRepository = RepositoryFactory.get('translation');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import store from '../../../store/store';
import vSelect from 'vue-select';

function initialState() {
  return {
    open: false,
    name: '',
    productId: null,
    usageInstructionsId: null,
    current_user_language_array: store.getters.current_user_language_array,
    form: {},
    availableLanArray: [],
  };
}

export default {
  name: 'ProductDescriptionPopUp',
  data() {
    return initialState();
  },
  components: {
    'v-select': vSelect,
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(productId, usageInstructionsId, names) {
      await Object.assign(this.$data, initialState());
      this.usageInstructionsId = usageInstructionsId;
      this.productId = productId;
      this.initialLanguageMap(names);
      this.open = true;
    },
    async initialLanguageMap(names) {
      const exists_languages = names.map((m) => m.Language);
      this.availableLanArray = this.current_user_language_array.filter(
        function (obj) {
          return exists_languages.indexOf(obj) === -1;
        },
      );
    },
    async onCLickSave() {
      const vue = this;
      this.$vs.loading();
      try {
        await TranslationRepository.productUsageInstructionsAddTranslation(
          this.productId,
          this.usageInstructionsId,
          this.form,
        );
        this.$vs.loading.close();
        await vue.$emit('dataReload');
        this.open = false;
        this.showSuccessMessage(
          'Usage Instruction Translation Added successfully!',
        );
      } catch (e) {
        this.showErrorMessage('Something went wrong!');
        this.$vs.loading.close();
      }
      this.$vs.loading.close();
    },
  },
};
</script>

<style scoped>
.discription-translation-popup >>> .vs-popup {
  min-height: 80% !important;
  width: 80% !important;
}

@media (min-width: 768px) and (max-width: 1319px) {
  .discription-translation-popup >>> .vs-popup {
    min-height: 50% !important;
    width: 80% !important;
  }
}
.items-per-page-handler {
  display: none;
}
</style>

